import "./styles.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Protected from "./Protected";
import Login from "./Pages/Login";
import Gv from "./Pages/Gv";
import Cat from "./Pages/Cat";
import Home from "./Pages/Home";
import Price from "./Pages/Price";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Catgallery from "./Pages/Catgallery";
// http://realphototbilisi.com   CKEditor  https://codesandbox.io/s/ckeditor-etku6?file=/src/CKEditorExample.js:140-148
export default function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/home" element={<Protected Cmp={Home} />} />
          <Route path="/about" element={<Protected Cmp={About} />} />
          <Route path="/price" element={<Protected Cmp={Price} />} />
          <Route path="/contact" element={<Protected Cmp={Contact} />} />
          <Route path="/gv/:id" element={<Protected Cmp={Gv} />} />
          <Route path="/cat" element={<Protected Cmp={Cat} />} />
          <Route path="/cat/:id" element={<Protected Cmp={Catgallery} />} />

          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}
