import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "../Utils/axios";

export default function Header() {
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  let user = JSON.parse(localStorage.getItem("user-info"));
  const navigate = useNavigate();
  function logOut() {
    localStorage.clear();

    navigate("/login");
  }
  // /onClick={logOut}
  const [list, setList] = useState([]);

  React.useEffect(() => {
    getDataList();
  }, []);

  let getDataList = async function () {
    axios
      .get("/gallery/menu/list")
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <header className="header">
      <NavLink className="logo" to="/home">
        Admin
      </NavLink>
      <input
        className="menu-btn"
        type="checkbox"
        id="menu-btn"
        checked={checked}
        onChange={handleChange}
      />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="nav-icon"></span>
      </label>
      <ul className="menu">
        {list.map((item) => {
          return (
            <li>
              <NavLink onClick={() => handleChange("")} to={"/" + item.type}>
                {item.title}
              </NavLink>
            </li>
          );
        })}

        <li>
          <NavLink onClick={logOut} to="">
            <i className="fa-solid fa-right-from-bracket"></i>
          </NavLink>
        </li>
      </ul>
    </header>
  );
}
