import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export default function About() {
  return (
    <div className="in">
      <NavLink
        className="image-upload-bt"
        to={"/cat/slider_about?title=ჩვენს შესახებ: სლაიდერი"}
      >
        <i className="fa-solid fa-image"></i> ჩვენს შესახებ სლაიდერი
      </NavLink>
      <NavLink
        className="image-upload-bt"
        to={"/gv/about_0?title=ჩვენს შესახებ"}
      >
        <i className="fa-solid fa-file-lines"></i> ჩვენს შესახებ ტექსტი
      </NavLink>
      <br />
      <br />
    </div>
  );
}
