import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";

export default function Editor({ value, handleChange }) {
  const editor = useRef(null);
  const config = {
    readonly: false,
    height: 400
  };

  return (
    <div className="App">
      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        onBlur={handleChange}
        onChange={(newContent) => {}}
      />
    </div>
  );
}
