import React from "react";
import { NavLink } from "react-router-dom";

export default function Contact() {
  return (
    <div className="in">
      <NavLink
        className="image-upload-bt"
        to={"/cat/slider_contact?title=კონტაქტი: სლაიდერი"}
      >
        <i className="fa-solid fa-image"></i> კონტაქტი სლაიდერი
      </NavLink>
      <NavLink className="image-upload-bt" to={"/gv/contact_0?title=კონტაქტი"}>
        <i className="fa-solid fa-file-lines"></i> კონტაქტი ტექსტი
      </NavLink>
      <br />
      <br />
    </div>
  );
}
