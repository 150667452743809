import React, { useState, useEffect } from "react";
import axios from "../Utils/axios";
import Editor from "../Utils/Editor";
import Button from "react-bootstrap/Button";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
  useNavigate,
  useParams,
  useLocation
} from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Gv(probs) {
  const [message, setMessage] = useState("");
  let { id } = useParams();
  let query = useQuery();
  let title = query.get("title");
  const navigate = useNavigate();

  useEffect(() => {
    //console.log(message);
    getData();
  }, []);

  let getData = async function () {
    const formData = new FormData();
    formData.append("text", message);
    axios
      .post("/gallery/gv/" + id, formData)
      .then((response) => {
        setMessage(response.data[0].text);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <h1>
        <Button variant="primary" onClick={() => navigate(-1)}>
          <i className="fa-solid fa-backward-step"></i>
        </Button>

        {title}
      </h1>
      <hr />
      <Editor
        value={message}
        handleChange={(e) => {
          setMessage(e);
        }}
      />
      <Button variant="primary" onClick={getData}>
        <i className="fa-solid fa-floppy-disk"></i>
      </Button>
      <div
        dangerouslySetInnerHTML={{ __html: message }}
        style={{
          display: "none",
          background: "lightblue",
          height: 500,
          position: "relative"
        }}
      />
    </div>
  );
}
