import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import axios from "../Utils/axios";

export function Catadd(props) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");

  async function addCat() {
    const formData = new FormData();
    formData.append("name", name);
    axios
      .post("/gallery/cat/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        console.log(res);
        setName("");
        setShow(false);
        props.refresh();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClose() {
    setShow(false);
  }
  function handleShow() {
    setShow(true);
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <i className="fa-solid fa-plus"></i> ახალი კატეგორიის დამატება
      </Button>
      <br />
      <br />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                placeholder="name"
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" title="დამატება" onClick={addCat}>
            <i className="fa-solid fa-plus"></i>{" "}
            <i className="fa-solid fa-floppy-disk"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
