import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import axios from "../Utils/axios";

export function Catedit(props) {
  const [name, setName] = useState("");
  React.useEffect(() => {
    getDataList();
  }, []);

  let listid = props.listid;
  let show = props.show;
  let onHide = props.onHide;

  let getDataList = async function () {
    axios
      .get("/gallery/cat/" + listid)
      .then((response) => {
        console.log(response.data[0].name);
        setName(response.data[0].name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function editCat() {
    const formData = new FormData();
    formData.append("name", name);
    axios
      .post("/gallery/cat/edit/" + listid, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        console.log(res);
        props.show = false;
        props.refresh();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function deleteCat() {
    if (window.confirm("ნამდვილად გსურთ წაშალოთ?")) {
      axios
        .post("/gallery/cat/delete/" + listid, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          console.log(res);
          props.refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <>
      <br />
      <br />
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                placeholder="name"
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" title="წაშლა" onClick={deleteCat}>
            <i className="fa-solid fa-trash"></i>
          </Button>
          <Button variant="primary" title="შეცვლა" onClick={editCat}>
            <i className="fa-solid fa-pen-to-square"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
