import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "../Utils/axios";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

export default function Home() {
  const [list, setList] = useState([]);

  React.useEffect(() => {
    getDataList();
  }, []);

  let getDataList = async function () {
    axios
      .get("/gallery/cat/list")
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="in">
      <NavLink
        className="image-upload-bt"
        to={"/cat/slider_main?title=მთავარ გვერდზე: სლაიდერი"}
      >
        <i className="fa-solid fa-image"></i> მთავარი გვერდის სლაიდერი
      </NavLink>
      <br />
      <br />
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th>კატეგორია</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td width="280px">
                  <NavLink
                    className="image-upload-bt"
                    to={
                      "/cat/main_cat_" +
                      item.id +
                      "?title=მთავარი გვერდის ფოტო: " +
                      item.name
                    }
                  >
                    <i className="fa-solid fa-file-lines"></i> მთავარი გვერდის
                    ფოტო
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
