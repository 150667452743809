import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "../Utils/axios";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Catadd } from "./Catadd.js";
import { Catedit } from "./Catedit.js";

export default function PhotoGallery() {
  const [list, setList] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [listid, setListid] = React.useState([]);

  React.useEffect(() => {
    getDataList();
  }, []);

  let getDataList = async function () {
    axios
      .get("/gallery/cat/list")
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  async function modal(id) {
    setModalShow(true);
    setListid(id);
  }

  let button;
  if (modalShow) {
    button = (
      <Catedit
        show={modalShow}
        listid={listid}
        onHide={() => setModalShow(false)}
        refresh={() => getDataList()}
      />
    );
  }

  return (
    <div className="in">
      <div id="demo"></div>
      <div className="CreateNewCat">
        {button}
        <Catadd refresh={() => getDataList()} />
        <NavLink
          className="image-upload-bt"
          to={"/cat/slider_cat?title= გალერეა: სლაიდერი"}
        >
          <i className="fa-solid fa-image"></i> გვერდის სლაიდერი
        </NavLink>
      </div>
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th>კატეგორია</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td width="50px">
                  <Button variant="primary" onClick={() => modal(item.id)}>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </Button>
                </td>
                <td width="160px">
                  <NavLink
                    className="image-upload-bt"
                    to={"/cat/cat_" + item.id + "?title=" + item.name}
                  >
                    <i className="fa-solid fa-image"></i> გალერეა
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
