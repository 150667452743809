import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "../Utils/axios";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

export default function Price() {
  const [list, setList] = useState([]);

  React.useEffect(() => {
    getDataList();
  }, []);

  let getDataList = async function () {
    axios
      .get("/gallery/cat/list")
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="in">
      <NavLink
        className="image-upload-bt"
        to={"/cat/slider_price?title= ფასები: სლაიდერი"}
      >
        <i className="fa-solid fa-image"></i> გვერდის სლაიდერი
      </NavLink>
      <br />
      <br />
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th>კატეგორია</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td width="150px">
                  <NavLink
                    className="image-upload-bt"
                    to={"/gv/cat_" + item.id + "?title=" + item.name}
                  >
                    <i className="fa-solid fa-file-lines"></i> ფასები
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
